<template>
  <b-card title="">
    <h3 class="text-primary">Logs</h3>
    <b-row>
      <b-col md="6" class="mb-1"> </b-col>
      <b-col md="5" class="mb-1"> </b-col>
      <b-col md="1" class="mb-1"> </b-col>
      <b-col class="mb-1">
        <b-row>
          <b-col class="col-4">
            <label for="">Filter</label>
            <b-form-select
              class=""
              v-model="logFilter"
              :options="logFilterOptions"
              @change="chooseAll()"
            >
            </b-form-select>
          </b-col>
          <b-col class="col-4">
            <div v-if="logFilter === 'users'">
              <label for="">Users</label>
              <b-form-select v-model="user" @change="getFilterUser(user.id)">
                <b-form-select-option
                  v-for="usr in userOptions"
                  :key="usr.id"
                  :value="usr"
                  >{{ usr.username }}</b-form-select-option
                >
              </b-form-select>
            </div>
            <div v-if="logFilter === 'applications'">
              <label for="">Applications</label>
              <b-form-select
                v-model="application"
                @change="getFilterApplication(application.id)"
              >
                <b-form-select-option
                  v-for="apli in applicationOptions"
                  :key="apli.id"
                  :value="apli"
                  >{{ apli.application_name }}</b-form-select-option
                >
              </b-form-select>
            </div>
          </b-col>
          <b-col class="col-4"></b-col>
        </b-row>
      </b-col>

      <b-col cols="12">
        <b-table
          responsive="lg"
          id="applogTable"
          :fields="fields"
          :items="applogTable"
          :per-page="perPage"
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
          <template #cell(appli)="data">
            <b-badge variant="primary">
              {{ data.item.application }}
            </b-badge>
          </template>
          <template #cell(activitytime)="data">
            <p>
              {{ new Date(data.item.start_time).toLocaleString() }}
              <!-- {{ data.item.date }} <br /> -->
              <!-- {{ data.item.start_time }} -->
            </p>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-row>
          <b-col class="d-flex align-items-center justify-content-end col-12">
            <b-pagination-nav
              v-model="currentPage"
              :numberOfPages="total"
              :link-gen="linkGen"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="applogTable"
              use-router
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BCardText,
  BTable,
  BAvatar,
  BBadge,
  BButton,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BPaginationNav,
  BInputGroup,
  BFormInput,
  VBModal,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BAvatar,
    BBadge,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BPaginationNav,
    BInputGroup,
    BFormInput,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      appid: "",
      application: "",
      applicationOptions: [],
      user: "",
      userOptions: [],
      logFilter: "all",
      logFilterOptions: [
        { value: "all", text: "All" },
        { value: "users", text: "Users" },
        { value: "applications", text: "Applications" },
      ],
      applogTable: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        "#",
        {
          key: "log_message",
          label: "Log Message",
          sortable: true,
        },
        {
          key: "appli",
          label: "Activity On",
        },
        {
          key: "user",
          label: "User Email",
        },
        {
          key: "activitytime",
          label: "Activity Time",
        },
      ],
      items: [],
      actions: [
        {
          1: "primary",
          2: "danger",
        },
      ],
      fireid: "",
    };
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      // this.load();
      this.getApplog(false);
    },
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getApplog();
    this.fetchApplication();
    this.fetchUser();
  },
  created() {
    // this.appid = this.$route.params.id;
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    getApplog: function (reset = true) {
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url = process.env.VUE_APP_BASEURL + "logs/logs/" + "?page=" + this.currentPage;
      const options = {
        method: "GET",
        url: url,
      };
      this.$http(options).then((res) => {
        this.applogTable = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    fetchApplication: function () {
      const options = {
        method: "GET",
        url: process.env.VUE_APP_BASEURL + "application/applications/",
      };
      this.$http(options).then((res) => {
        this.items = res.data;
        this.applicationOptions = res.data.results;
        // this.applicationOptions.forEach((ele) => {
        //   this.app_options.push(ele);
        // });
      });
    },
    fetchUser: function () {
      const options = {
        method: "GET",
        url: process.env.VUE_APP_BASEURL + `user/users`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.userOptions = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFilterApplication(appid) {
      const options = {
        method: "GET",
        url:
          process.env.VUE_APP_BASEURL +
          `logs/logs/?application_id=${this.application.id}`,
      };
      this.$http(options).then((res) => {
        this.applogTable = res.data.results;
      });
    },
    getFilterUser() {
      const options = {
        method: "GET",
        url: process.env.VUE_APP_BASEURL + `logs/logs/?user_id=${this.user.id}`,
      };
      this.$http(options).then((res) => {
        this.applogTable = res.data.results;
      });
    },
    chooseAll() {
      if (this.logFilter == "all") {
        this.getApplog();
      }
    },
  },
};
</script>

<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #7367f0 !important;
  color: #fff !important;
}
[dir] .table th,
[dir] .table td {
  padding: 0.72rem 2rem;
  text-align: center;
}
</style>
